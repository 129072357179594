import React from "react";
import { Link, graphql } from "gatsby";
import useScript from "../hooks/useScript";
import Layout from "../components/layout";
// import SocialShare from "../components/SocialShare";
// import ContactsBlockProjects from "../components/ContactsBlockProjects";
import IconMail from "../images/icons/theme/communication/send.svg";
import StructuredTextRenderer, {
  getUnit,
  StructuredModuleTypes,
} from "../components/StructuredTextRenderer";
import { FaqJson } from "../components/Faq";
import { ProjectJsonLd } from "../components/jsonld/ProjectJsonLd";
import { GatsbyImage } from "gatsby-plugin-image";

// import ImagePortfolioItem4 from '../images/photos/portfolio-item-4.jpg';
// import ImagePortfolioItem5 from '../images/photos/portfolio-item-5.jpg';
// import ImagePortfolioItem6 from '../images/photos/portfolio-item-6.jpg';

const defaultBgColor = "#14213D";

const ProjectTemplate = ({ data, location: { pathname } }) => {
  const ProjectData = data.datoCmsProject;
  const ProjectsData = data.allDatoCmsProject.edges;

  const ProjectColor = ProjectData.cardColor.hex;

  const structuredText = data.datoCmsProject.contentStructuredText;

  const faqs = structuredText.blocks
    .filter((b) => b.__typename === StructuredModuleTypes.DatoCmsFaqItem)
    .map(({ answer, question }) => ({
      answer,
      question,
    }));

  return (
    <Layout
      meta={{
        seo: ProjectData.seo,
        path: pathname,
        disableSearchIndexing: ProjectData.secretProject,
      }}
    >
      <FaqJson faqs={faqs} />
      <ProjectJsonLd project={ProjectData} />

      <div className="project-template-page">
        <section className="first-screen bg-dark bg-primary-3 min-vh-50 text-light d-flex align-items-end py-0">
          <GatsbyImage
            loading="eager"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            className="opacity-80"
            imgStyle={{ objectPosition: "right" }}
            imgClassName="bg-img jarallax-img"
            alt={ProjectData.title}
            image={ProjectData.imageBackground.gatsbyImageData}
          />

          <div className="container">
            <div className="row">
              <div className="col mb-5">
                <h1 className="display-4 mb-1">{ProjectData.title}</h1>
                <span className="lead">{ProjectData.categoryProject}</span>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-4 mb-md-0">
                <dl className="row lead">
                  <dt className="col-3 mb-2" style={{ color: ProjectColor }}>
                    Client
                  </dt>
                  <dd className="col-9 mb-2">{ProjectData.client}</dd>

                  <dt className="col-3 mb-2" style={{ color: ProjectColor }}>
                    Role
                  </dt>
                  <dd className="col-9 mb-2">{ProjectData.role}</dd>

                  <dt className="col-3 mb-2" style={{ color: ProjectColor }}>
                    Industry
                  </dt>
                  <dd className="col-9 mb-2">{ProjectData.industry}</dd>
                </dl>
              </div>
              <div className="col proj-desc">
                <div className="row justify-content-center">
                  <div className="col-lg-11 lead">
                    <StructuredTextRenderer
                      data={ProjectData.descriptionStructuredText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-2 pb-0 o-hidden">
          <div className="container mb-6 pb-6">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {/*<figure>
                  <Img fluid={ProjectData.imageContent.fluid} alt="portfolio" className="rounded" />
                </figure>*/}
                <article className="article article-portfolio article-project">
                  <StructuredTextRenderer
                    data={structuredText}
                  />
                </article>
              </div>
            </div>
          </div>

          {/* <div className="container pt-3 pb-6">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-10">
                <hr />
                <SocialShare link={pathname} />
              </div>
            </div>
          </div> */}
        </section>

        <section className="section-contacts bg-primary-3 pb-0">
          <div className="container">
            <div className="row h-100">
              <div className="col-12 col-md-6 text-light h-100">
                <div className="badge badge-primary-2 mt-2 mb-2">
                  Drop us a line
                </div>
                <h2 className="display-4">Have a similar project in mind?</h2>

                <p className="lead">
                  Our UX studio will take you from a rough idea to a complete
                  design in no time:
                </p>
                <ul className="list-unstyled mt-4">
                  <li className="mb-5 d-flex">
                    <IconMail className="icon bg-yellow icon-md" />
                    <div className="ml-3 h3">
                      <a href="mailto:contact@adamfard.com">
                        contact@adamfard.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 section-contacts__form">
                <div className="card card--common card-body">
                  <div id="activecampaign-form">
                    <div id="activecampaign-form__wrapper">
                      <div className=" _form_3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=3")}
        </section>

        <section>
          <div className="container">
            <div className="row mb-4">
              <div className="col">
                <h3 className="h2">More Case Studies</h3>
              </div>
            </div>
            <div className="row">
              {ProjectsData.map(({ node }) => (
                <div
                  className="card-case card-related col-sm-6 col-lg-4 mb-4"
                  key={node.id}
                >
                  <Link to={`/projects/${node.slug}`} className="d-block">
                    <div
                      className="rounded"
                      style={{
                        backgroundColor: node.cardColor.hex || defaultBgColor,
                      }}
                    >
                      <GatsbyImage
                        image={node.cardImage.gatsbyImageData}
                        className="rounded mb-3"
                        alt="card"
                        loading={"lazy"}
                      />
                    </div>
                    <h4 className="mb-1">{node.title}</h4>
                    <div className="text-small text-muted">
                      {node.categoryProject}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplate($id: String!) {
    datoCmsProject(id: { eq: $id }) {
      title
      categoryProject

      client
      role
      industry

      cardColor {
        hex
      }

      descriptionStructuredText {
        value
        blocks {
          __typename
          ... on DatoCmsFaqItem {
            id: originalId
            answer
            question
          }
          ... on DatoCmsImageBlock {
            id: originalId
            caption
            noVerticalMargin
            image {
              customData
              url
              alt
              gatsbyImageData(width: 2500, layout: CONSTRAINED)
            }
          }
          ... on DatoCmsTestimonialblock {
            id: originalId
            avatar {
              url
              alt
              gatsbyImageData(width: 64, height: 64, layout: FIXED)
            }
            content
            logo {
              url
              alt
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            badge
            buttonText
            title
            ctaContent
            buttonLink
            image {
              customData
              alt
              title
              gatsbyImageData(width: 1800, layout: CONSTRAINED)
            }
          }
          ... on DatoCmsScrolltocta {
            id: originalId
            content
          }

          ... on DatoCmsHtmlBlock {
            id: originalId
            htmlField
            htmlCaption
          }
        }
      }

      contentStructuredText {
        value
        blocks {
          __typename
          ... on DatoCmsFaqItem {
            id: originalId
            answer
            question
          }
          ... on DatoCmsImageBlock {
            id: originalId
            caption
            noVerticalMargin
            edgeToEdge
            paddingBottom
            limitedWidth
            image {
              customData
              url
              alt
              gatsbyImageData(width: 2500, layout: CONSTRAINED)
            }
          }
          ... on DatoCmsTestimonialblock {
            id: originalId
            avatar {
              url
              alt
              gatsbyImageData(width: 64, height: 64, layout: FIXED)
            }
            content
            logo {
              url
              alt
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            badge
            buttonText
            title
            ctaContent
            buttonLink
            image {
              customData
              alt
              title
              gatsbyImageData(width: 1800, layout: CONSTRAINED)
            }
          }
          ... on DatoCmsScrolltocta {
            id: originalId
            content
          }

          ... on DatoCmsHtmlBlock {
            id: originalId
            htmlField
            htmlCaption
          }
        }
      }
      imageBackground {
        url
        gatsbyImageData(layout: FULL_WIDTH)
      }
      slug
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      secretProject
    }

    allDatoCmsProject(
      limit: 3
      filter: {
        id: { ne: $id }
        secretProject: { ne: true }
        hiddenProject: { ne: true }
      }
    ) {
      edges {
        node {
          title
          categoryProject
          cardImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          cardColor {
            hex
          }
          id
          slug
        }
      }
    }
  }
`;
